import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dav-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent {
  @Input() buttonFixed = false;
}
