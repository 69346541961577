import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DavAtomsAngularModule } from "@npm-davi/davi-coe-atoms-angular-lib";
import { BenefitsTrackerComponent } from "./benefits-tracker/benefits-tracker.component";
import { ButtonRegKeyComponent } from "./button-reg-key/button-reg-key.component";
import { CardAccountsComponent } from "./card-accounts/card-accounts.component";
import { CardResumListComponent } from "./card-resum-list/card-resum-list.component";
import { MobileLayoutComponent } from "./mobile-layout/mobile-layout.component";
import { ResultPageComponent } from "./result-page/result-page.component";
import { ResumePageComponent } from "./resume-page/resume-page.component";
import { StepperComponent } from "./stepper/stepper.component";
import { TitleContainerComponent } from "./title-container/title-container.component";
import { LoaderComponent } from "./loader/loader.component";

@NgModule({
  declarations: [
    StepperComponent,
    MobileLayoutComponent,
    CardResumListComponent,
    TitleContainerComponent,
    BenefitsTrackerComponent,
    ButtonRegKeyComponent,
    ResultPageComponent,
    CardAccountsComponent,
    ResumePageComponent,
    LoaderComponent,
  ],
  exports: [
    StepperComponent,
    MobileLayoutComponent,
    CardResumListComponent,
    TitleContainerComponent,
    BenefitsTrackerComponent,
    ButtonRegKeyComponent,
    ResultPageComponent,
    CardAccountsComponent,
    ResumePageComponent,
    LoaderComponent,
  ],
  imports: [CommonModule, DavAtomsAngularModule],
})
export class LocalComponentsModule {}
