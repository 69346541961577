import { Component, inject } from "@angular/core";
import { ENDPOINT, PAGE } from "./constants/pages";
import { PageState } from "./model/state.model";
import { PostmessageRepository } from "./repository/postmessage.repository";
import { StateRepository } from "./repository/state.repository";
import { TaggingRepository } from "./repository/tagging.repository";
import { ApiUsecase } from "./usecase/api.usecase";
import { ParamsUsecase } from "./usecase/params.usecase";
import { IDENTIFIERS_TAGGING } from "./constants/tagging";

@Component({
  template: "",
})
export class PageComponent {
  public stateRepo = inject(StateRepository);
  private readonly paramsUsecase = inject(ParamsUsecase);
  public apiUsecase = inject(ApiUsecase);
  public postmessageRepo = inject(PostmessageRepository);
  public taggingRepo = inject(TaggingRepository);

  constructor(public pageID: PAGE) {
    this.stateRepo.setCurrentPage(pageID);
  }

  public async loadDataPage(callback: () => any): Promise<void> {
    const stepsLoaded = await this.paramsUsecase.loadParamsPage(this.pageID);
    this.stateRepo.setPagesLoaded(stepsLoaded);
    this.setTitleChannel(this.dataPage?.paramsPage?.titleChannel ?? "Zona Llaves");
    this.stateRepo.$showLoader.emit(false);

    callback();
  }

  get dataPage(): PageState | undefined {
    return this.stateRepo.getPagesLoaded()[this.pageID];
  }

  public async sendContextPage(context?: string) {
    if (context && context !== "") {
      this.postmessageRepo.sendPostmessage({
        fn: "changeContext",
        target: "MOBILE",
        ps: context,
      });
    }
  }

  public setTitleChannel(title: string) {
    const pm = {
      fn: "setUpTitle",
      target: "MOBILE",
      message: {
        upTitle: title,
      },
    };
    this.postmessageRepo.sendPostmessage(pm);
  }

  public previousPage(extraData?: { goTo?: string; [key: string]: any }) {
    const currentPageState = this.stateRepo.getPagesLoaded()[this.pageID]!;

    if (currentPageState?.previousPage) {
      const previousPageState =
        this.stateRepo.getPagesLoaded()[currentPageState.previousPage];

      this.apiUsecase.nextPage(
        currentPageState.previousPage,
        {
          ...previousPageState?.currentDataPage,
          ...extraData,
        },
        true
      );
    } else {
      this.postmessageGoBack(extraData?.goTo);
    }
  }

  public postmessageGoBack(goTo?: string) {
    const token = this.stateRepo.getUserId();
    const entryPoint = goTo ?? this.stateRepo.getEntryPoint();

    const pm = {
      fn: "appFinish",
      target: "WEB",
      message: {
        status: "ok",
        statusCode: 200,
        statusMessage: "op_" + entryPoint,
        token: token,
      },
    };

    this.postmessageRepo.sendPostmessage(pm);
  }

  public callApi(endpoint: ENDPOINT, data: any) {
    return this.apiUsecase.callApi(endpoint, data);
  }

  public nextPage(nextPageId: PAGE, data?: any, noSavePrevious?: boolean) {
    return this.apiUsecase.nextPage(nextPageId, data, noSavePrevious);
  }

  public loginTag(flow: string) {
    this.taggingRepo.sendTag({
      key: IDENTIFIERS_TAGGING.LOGIN,
      structure: {
        Flujo: flow,
        event: "login",
        userId: this.stateRepo.getUserId(),
      },
      data: {},
    });
  }

  public saveResume(
    endpoint: ENDPOINT,
    page: PAGE,
    data: { request: any; extraData?: any }
  ) {
    this.callApi(ENDPOINT.SAVE_RESUME, {
      endpoint,
      page,
      data,
    });
  }

  public postMessageChangeHeader(): void {
    this.postmessageRepo.sendPostmessage({
      fn: "changeHeader",
      target: "MOBILE",
      message: {
        statusMessage: "op_fin",
        token: this.stateRepo.getUserId()
      }
    })
  }
}
