import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IlistItemAccount } from "../../interfaces/cadResume.interfaces";

@Component({
  selector: "app-card-accounts",
  templateUrl: "./card-accounts.component.html",
  styleUrl: "./card-accounts.component.scss",
})
export class CardAccountsComponent {
  @Input() items: IlistItemAccount[] = [];
  @Output() clickButtonEdit = new EventEmitter();
}
