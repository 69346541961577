import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  ITrakerBenefits,
} from "../../interfaces/benefits-traker.interfaces";

@Component({
  selector: "app-benefits-tracker",
  templateUrl: "./benefits-tracker.component.html",
  styleUrl: "./benefits-tracker.component.scss",
})
export class BenefitsTrackerComponent {
  @Input() items: ITrakerBenefits[] = [];

  @Input() currentStepProp = 1;
  @Input() stepsProp = 1;
  @Input() buttonStepTextProp = "";
  @Input() hiddenSkipButton = true;

  @Output() clickButton = new EventEmitter();

  currentStep: number = this.currentStepProp;

  setStep(step: any) {
    this.currentStep = step;
  }

  constructor(){
    console.log('BenefitsTrackerComponent', this.items);
  }
}
