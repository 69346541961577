<div class="dav-bgResults"></div>
<div class="dav-tittleResults" *ngFor="let item of itemsResult; let i = index">
  <dav-text color="white" size="xl" weight="bold">{{item.titleResum}}</dav-text>
</div>
<dav-content-box class="mx-8px" color="white" radius="16px" size="lg" radiusStyle="full"
  *ngFor="let item of itemsResult; let i = index">
  <dav-text size="md" color="black-dark" weight="semi-bold">{{item.labelResum}}</dav-text>
  <div class="mt-16px" *ngIf="item.showSubtitle">
    <dav-text size="sm" color="black-light">{{item.subtitleContent}}</dav-text>
  </div>
  @if ( showSnackBar) {

    <dav-snackbar type="{{item.snackType}}" class="mt-16px mb-16px"
      displayMode="block">{{item.snackContent}}</dav-snackbar>
  
  }
  <ng-content>
  </ng-content>

</dav-content-box>
@for(item of itemsResult; track $index) {
  @if ( item.available && showRegisterAnotherKey ) {
    <app-button-reg-key (click)="clickButton.emit(item.regKeyText)" onkeydown="clickButton.emit(item.regKeyText)">{{item.regKeyText}}</app-button-reg-key>
  }
}