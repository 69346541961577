import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IlistPage } from "../../interfaces/pageResume.interfaces";

@Component({
  selector: "app-result-page",
  templateUrl: "./result-page.component.html",
  styleUrl: "./result-page.component.scss"
})
export class ResultPageComponent {
  @Input() itemsResult: IlistPage[] = [];
  @Output() clickButton = new EventEmitter();

  @Input() showRegisterAnotherKey = true; 
  @Input() showSnackBar = true;
}
