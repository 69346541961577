<app-dav-mobile-layout >
  <div content>
    <div class="dav-bgResults" *ngIf="showHeader"></div>
    <div class="dav-tittleResults" *ngIf="showHeader">
      <dav-text *ngFor="let item of itemsResume; let i = index" color="white" size="xl"
        weight="bold">{{item.titleResum}}</dav-text>
    </div>

    <div class="my-8px" *ngIf="!showHeader">
      <app-title-container title="{{params?.title}}" (clickBackButton)="clickBackButton.emit()"></app-title-container>
    </div>

    <dav-content-box class="mx-8px" color="white" radius="16px" size="lg" radiusStyle="full"
      *ngFor="let item of itemsResume; let i = index">
      <section class="dav-resumeHeader" *ngIf="showHeader">
        <img class="dav-resumeHeader__icon" src="../../../../assets/images/logo-davivienda.png" alt="">
        <dav-text color="black-light" size="xs">{{params?.voucherNumberTitle}}</dav-text>
        <dav-text color="black-dark" size="sm" [isCondensed]="true" weight="bold">{{item.numberVoucher}}</dav-text>
        <dav-text color="black-light" size="2xs">{{item.dateVoucher}}</dav-text>

        <hr class="dav-separator my-8px">
      </section>
      <section class="dav-resumeBody">

        <div class="dav-resumeBody__originData">
          <dav-text color="black-light" size="xs">{{params?.originText}}</dav-text>
          <dav-text color="black-main" size="sm" weight="bold">{{item.labelOrigin}}</dav-text>
          <dav-text color="black-dark" size="xs" [isCondensed]="true" weight="bold">{{item.numberOrigin}}</dav-text>
        </div>

        <div class="my-16px">
          <dav-icon color="#E1111C" [name]="'arrowBottom'" backgroundColor="#F1F2F8" backgroundSize="16px"></dav-icon>
        </div>

        <div class="dav-resumeBody__destData">
          <dav-text color="black-light" size="xs">{{params?.destinationText}}</dav-text>
          <dav-text color="black-main" size="sm" [isCondensed]="true" weight="bold">{{item.userDest}}</dav-text>
          <dav-text color="black-light" size="2xs">{{item.labelDest}}</dav-text>
          <dav-text color="black-dark" size="2xs" weight="bold" [isCondensed]="true">{{item.numberDest}}</dav-text>
        </div>

        <div class="dav-geyCardResum">
          <dav-text color="black-main" size="xs">{{params.amountText}}</dav-text>
          <dav-text color="black-dark" size="lg" [isCondensed]="true" weight="bold">{{item.resumeValue}}</dav-text>
          <hr class="dav-separator my-8px">
          <div class="dav-geyCardResum__subInfo">
            <dav-text color="black-light" size="2xs">{{params.transactionCostText}}</dav-text>
            <dav-text color="black-dark" size="xs" weight="bold" [isCondensed]="true">{{item.subInfoValue}}</dav-text>
          </div>
          <hr class="dav-separator my-8px">
          <div class="dav-geyCardResum__subInfo">
            <dav-text color="black-light" size="2xs">{{params.ivaCostText}}</dav-text>
            <dav-text color="black-dark" size="xs" weight="bold" [isCondensed]="true">{{item.subInfoIVA}}</dav-text>
          </div>
        </div>
      </section>
    </dav-content-box>

    @if (params.helperText) {
      <dav-helper class="mt-16px ml-24px mr-24px" size="sm">
        {{params.helperText}}
      </dav-helper>
    }

  </div>

  <div footer>

    <div class="dav-contButtonShared" *ngIf="showHeader && params.switch.sharedVoucher">
      <button class="dav-contButtonShared__button" type="button" (click)="clickButton.next('shared')"><dav-icon
          name="share" color="#717171"></dav-icon></button>
      <dav-text textAlign="center" color="black-main" size="2xs" [innerHTML]="params.sharedVoucher"></dav-text>
    </div>

    <div class="dav-contButton " *ngIf="showHeader && params.switch.finally">
      <dav-button buttonSize="lg" buttonText="Finalizar" (click)="clickFinallyButton.next('finally')" (keydown)="{}"></dav-button>
    </div>

    <div class="dav-contButton mt-0px" *ngIf="!showHeader">
      <dav-button [disabled]="disabled" buttonSize="lg" buttonText="Enviar dinero" (click)="clickButton.next('send')" (keydown)="{}"></dav-button>
    </div>
  </div>
</app-dav-mobile-layout >
