<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div [ngClass]="{'dav-cardResume': !notAvailable, 'dav-cardResume--disable': notAvailable}">
  <div *ngFor="let item of items; let i = index" (click)="clickButton(item, $event)" (keydown)="{}">
    <div class="dav-contResum">
      <div class="dav-contResum--contleft">
        <dav-icon *ngIf="item.nameIcon" name="{{item.nameIcon}}"></dav-icon>
        <div *ngIf="item.title" class="dav-contResum--text">
          <div class="dav-contResum--textFelx">
            <dav-text tag="p" isCondensed="{{item.isCondensedTitle}}" size="{{item.titleSize}}" color="black-dark"
              weight="{{item.fonWeightTitle}}">{{ item.title }}</dav-text>
            <dav-badge *ngIf="badgeTitle" color="green"><dav-text size="3xs" color="#E5F3EC"
                weight="semi-bold">{{item.badgeTitleText}}</dav-text></dav-badge>
          </div>
          <dav-text isCondensed="{{item.isCondensedInfo}}" *ngIf="item.subInfo" tag="p" size="{{item.subInfoSize}}"
            color="black-dark" weight="{{item.subInfoFonWeight}}">{{ item.subInfo }}</dav-text>
        </div>
      </div>
      <button type="button">
        <dav-icon *ngIf="!notAvailable && !checkItem  && rowIcon" name="chevronRight"></dav-icon>
      </button>
      <dav-badge *ngIf="notAvailable || item.badgeText" color="orange"><dav-text size="3xs" color="#BF5300"
          weight="semi-bold">{{item.badgeText}}</dav-text></dav-badge>
      <dav-radio *ngIf="checkItem && !item.badgeText" id="radio_{{item.obj?.value || i}}" radioId="{{radioId}}" name="{{radioName}}" radioStyle="check" (click)="clickButton(item, $event)" (keydown)="{}"></dav-radio>
    </div>
    <div *ngIf="item.itemGreyTitle" class="dav-contResum--contItemGrey">
      <dav-text tag="p" size="2xs" color="black-dark">{{ item.itemGreyTitle }}</dav-text>
      <dav-text tag="p" [isCondensed]="true" weight="bold" size="sm" color="black-dark">{{ item.itemGreyNumber
        }}</dav-text>
    </div>
    <hr class="dav-separator my-16px" *ngIf="i < items.length - 1">
  </div>
</div>