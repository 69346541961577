import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IlistItem } from "../../interfaces/cadResume.interfaces";

@Component({
  selector: "app-card-resum-list",
  templateUrl: "./card-resum-list.component.html",
  styleUrl: "./card-resum-list.component.scss",
})
export class CardResumListComponent implements OnInit {
  @Input() items: IlistItem[] = [];
  @Input() notAvailable = false;
  @Input() checkItem = false;
  @Input() rowIcon = true;
  @Input() badgeTitle = false;
  @Input() radioId = "";
  @Input() radioName = ""; 

  @Output() clickItem = new EventEmitter<IlistItem>();

  ngOnInit(): void {

    this.items.forEach(i => {
      if(i?.obj?.['selected']){
        const radioId = "radio_" + i?.obj?.value;
        setTimeout(() => {
          (
            document
              .getElementById(radioId)
              ?.getElementsByClassName("check")[0] as any
          )?.click();
        }, 100);
      }
    })
 
  }

  clickButton(item: IlistItem, event: any) {  
    if (this.checkItem) {
      if (event.target.className != "dav-contResum" && !item.badgeText) this.clickItem.emit(item);
    } else {
      this.clickItem.emit(item);
    }
  }
}
