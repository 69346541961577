<div class="container">
  <div class="head">
    <ng-content select="[head]"></ng-content>
  </div>
  <div class="content">
    <ng-content select="[content]"></ng-content>
  </div>
  <div [ngClass]="buttonFixed ? 'footerFixed' : 'footer'">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
