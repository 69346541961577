import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ResumePage } from "../../interfaces/pageResume.interfaces";

@Component({
  selector: "app-resume-page",
  templateUrl: "./resume-page.component.html",
  styleUrl: "./resume-page.component.scss",
})
export class ResumePageComponent {
  @Input() showHeader = false;
  @Input() disabled = false;
  @Input() itemsResume: ResumePage[] = [];
  @Output() clickButton = new EventEmitter();

  @Input() params: any = undefined;
  @Output() clickBackButton = new EventEmitter();
  @Output() clickFinallyButton = new EventEmitter();
}
