import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
})
export class StepperComponent {
  @Input() steps = 5;
  @Input() currentStep = 1;
  @Input() buttonStepText = "Default text";

  @Output() step = new EventEmitter();
  @Output() buttonPress = new EventEmitter();

  nextStep() {
    this.currentStep =
      this.currentStep < this.steps ? this.currentStep + 1 : this.currentStep;
    this.step.emit(this.currentStep);
    this.buttonPress.emit("next");
  }

  previousStep() {
    this.currentStep =
      this.currentStep > 1 ? this.currentStep - 1 : this.currentStep;
    this.step.emit(this.currentStep);
    this.buttonPress.emit("back");
  }
}
