<article class="dav-cardContent" *ngFor="let item of items; let i = index">
  <div class="dav-cardContent__head">
    <div class="dav-cardContent__head--text">
      <dav-text tag="p" size="sm" color="black-dark" weight="bold">{{
        item.title
      }}</dav-text>
      <dav-text tag="p" size="2xs" color="black-dark" isCondensed="true">{{
        item.subInfo
      }}</dav-text>
    </div>
    <button type="button" (click)="clickButtonEdit.emit('edit')">
      <dav-icon name="edit"></dav-icon>
    </button>
  </div>
  <hr class="dav-separator my-8px" />
  <div class="dav-cardContent__content">
    <div class="dav-cardContent__content--textContent">
      <dav-text tag="p" size="2xs" color="black-dark">{{
        item.contentTitle
      }}</dav-text>
      <dav-text
        tag="p"
        [isCondensed]="true"
        weight="bold"
        size="sm"
        color="black-dark"
        >{{ item.contentNumber }}</dav-text
      >
    </div>
  </div>
</article>
