<div class="dav-stepper">
  <button class="dav-stepper__arrow--light" (click)="previousStep()">
    <dav-icon color="#E1111C" name="arrowLeft"></dav-icon>
  </button>
  <dav-text size="xs" *ngIf="steps > 1">
    {{this.currentStep + ' de ' + this.steps}}
  </dav-text>
  <button [ngClass]="this.currentStep === this.steps && buttonStepText !== '' ? 'dav-stepper__arrow--last':'dav-stepper__arrow--dark'"
    (click)="nextStep()">
    <dav-text color="#FFFFFF" size="xs" weight="bold" *ngIf="this.currentStep === this.steps">{{buttonStepText}}</dav-text>
    <dav-icon name="arrowRight" color="#FFF"></dav-icon>
  </button>
</div>


