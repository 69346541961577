import { Component } from '@angular/core';

@Component({
  selector: 'app-button-reg-key',
  templateUrl: './button-reg-key.component.html',
  styleUrl: './button-reg-key.component.scss'
})
export class ButtonRegKeyComponent {

}
