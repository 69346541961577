<main class="dav-container BGgray">
  <app-dav-mobile-layout>
    <section class="dav-benefits" content>
      <div class="dav-contHead">
        <a class="dav-link dav-linkFloat" aria-disabled="true"
          *ngIf="this.currentStep === this.stepsProp && !hiddenSkipButton" (click)="clickButton.emit('omit');"
          (keydown)="{}"><dav-text weight="regular" color="black-main">Omitir</dav-text></a>
      </div>
      @for (item of items; track $index) {
      <div class="dav-benefits__content">
        @if($index + 1 === currentStep) {
          @if(item.icon) {
          <img src={{item.icon}} alt="">
          }
  
          @if (item.title) {
          <dav-text tag="h3" size="xl" weight="bold" color="black-dark">{{item.title}}</dav-text>
          }
  
          @if(item.info) {
          <dav-text tag="p" color="black-light">
            <p innerHTML="{{item.info}}"></p>
          </dav-text>
          }
  
          @if(item.subinfo) {
          <dav-text tag="p" color="black-light">
            <p innerHTML="{{item.subinfo}}"></p>
          </dav-text>
          }
  
          @if(item.itemList){
          <ul class="dav-listForBenefits">
            <li class="dav-listForBenefits__item" *ngFor="let itemlist of item.itemList"><dav-text tag="p" size="sm"
                color="black-light">{{itemlist}}</dav-text>
            </li>
          </ul>
          }
  
          @if ( item.snackbar) {
          <dav-snackbar class="mb-24px" displayMode="block">
            <p>{{item.snackbar.title}}</p>
            {{item.snackbar.text}}
          </dav-snackbar>
          }

          @if(item.lastInfo) {
            <dav-text tag="p" color="black-light">
              <p innerHTML="{{item.lastInfo}}"></p>
            </dav-text>
            }
        }
      </div>
      }

    </section>
    <div footer>
      <app-stepper [currentStep]="currentStepProp" [steps]="stepsProp" buttonStepText="{{buttonStepTextProp}}"
        (step)="setStep($event)" (buttonPress)="clickButton.emit($event);"></app-stepper>
    </div>
  </app-dav-mobile-layout>
</main>