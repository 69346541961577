import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-title-container",
  templateUrl: "./title-container.component.html",
  styleUrl: "./title-container.component.scss",
})
export class TitleContainerComponent {
  @Input() title = "Default Tittle";
  @Output() clickBackButton = new EventEmitter<any>();
}
