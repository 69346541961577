import { inject } from "@angular/core";
import { PAGE } from "../constants/pages";
import { PARAMS_KEYS } from "../constants/params";
import { PageState } from "../model/state.model";
import { LoggerRepository } from "../repository/logger.repository";
import { ParamsRepository } from "../repository/params.repository";
import { StateRepository } from "../repository/state.repository";

export class ParamsUsecase {
  private readonly loggerRepo = inject(LoggerRepository);
  private readonly stateRepo = inject(StateRepository);
  private readonly paramsRepo = inject(ParamsRepository);

  async loadParamsPage(
    currentPage: PAGE
  ): Promise<{ [pageId in PAGE]?: PageState }> {
    const token = this.stateRepo.getBearerToken();
    const omitLoadParams = this.stateRepo.getOmitLoadParams();

    const stepsLoaded = this.stateRepo.getPagesLoaded() || {};
    if (!stepsLoaded[currentPage]?.paramsPage && !omitLoadParams) {
      const paramsPage = await this.paramsRepo.getParam(
        PARAMS_KEYS.PARAMS,
        token,
        currentPage
      );

      this.loggerRepo.debug("Parametros cargados: " + currentPage, paramsPage);
      stepsLoaded[currentPage] = {
        previousPage: null,
        currentDataPage: {},
        paramsPage: paramsPage,
      };

      this.stateRepo.setTitle(paramsPage?.tagging?.title);
      this.stateRepo.setUrl(paramsPage?.tagging?.url);
    }else if(omitLoadParams){
      stepsLoaded[currentPage] = {
        previousPage: null,
        currentDataPage: {},
        paramsPage: {} as any,
      };
    }

    this.stateRepo.setOmitLoadParams(false);

    return stepsLoaded;
  }

  async loadModals(): Promise<void> {
    const token = this.stateRepo.getBearerToken();

    const modalsData = await this.paramsRepo.getParam(
      PARAMS_KEYS.MODALS,
      token
    );

    if (modalsData) {
      this.stateRepo.setModals(modalsData);
      this.loggerRepo.debug("Modales cargados", modalsData);
    }
  }
}
